<template>
  <ModalComp
    :title-text="state.modal.title"
    title-icon-class="icon-settings"
    :loading="state.form.submitting"
    :show="state.modal.show"
    :mode="updating ? 'change' : 'create'"
    :ok-button-text="state.modal.submitBtnText"
    @hide="handleClose"
    @submit="handleSubmit"
  >

    <form class="row">
      <!--key name-->
      <div class="form-group col-12">
        <label for="settingKey" class="col-form-label">
          Setting Name:
          <span class="text-danger">*</span>
        </label>
        <input
          type="text"
          class="form-control"
          id="settingKey"
          placeholder="Setting name"
          v-model.trim="state.form.name.value"
          autocomplete="off"
          spellcheck="false"
          :maxlength="state.form.name.maxAllowed"
          :readonly="updating"
        >
        <div class="d-flex justify-content-between">
          <FormErrorMsg :error="state.form.name.error" />
          <span />
          <BadgeComp
            classes="mt-1"
            :styles="{height: 'fit-content'}"
            type="dar"
          >
            {{ state.form.name.value.length }}/{{ state.form.name.maxAllowed }}
          </BadgeComp>
        </div>
      </div>

      <!--group-->
      <div class="form-group col-12">
        <label for="settingGroup" class="col-form-label">
          Setting Group:
          <span class="text-danger">*</span>
        </label>
        <div class="input-group flex-nowrap" id="groupAddEditGroup">
          <TreeSelect
            v-if="!state.form.addNewGroup"
            placeholder="Select Group"
            v-model="state.form.group.value"
            :options="state.form.group.options"
            :multiple="false"
            :searchable="false"
            :clearable="false"
            :disableBranchNodes="true"
          />

          <input
            v-else
            type="text"
            class="form-control"
            id="settingGroup"
            placeholder="Group name"
            v-model.trim="state.form.group.value"
            autocomplete="off"
            spellcheck="false"
          />

          <div class="input-group-append text-nowrap">
            <button
              type="button"
              :class="`btn btn-${state.form.addNewGroup ? 'secondary' : 'primary'}`"
              @click="state.form.addNewGroup = !state.form.addNewGroup"
            >
              {{ state.form.addNewGroup ? 'Cancel' : 'New' }}
            </button>
          </div>
        </div>
        <FormErrorMsg :error="state.form.group.error" />
      </div>

      <!--Admin only-->
      <div
        class="form-group col-6"
        v-if="!updating"
      >
        <label class="col-form-label me-5">Admin Only</label>
        <switch-comp
          :checked="state.form.adminOnly"
          enable-text="Enabled"
          disable-text="Disabled"
          @change="val=>state.form.adminOnly = val"
        />
      </div>

      <!--deletable-->
      <div class="form-group col-6"
           v-if="!updating"
      >
        <label class="col-form-label me-5">Deletable</label>
        <switch-comp
          :checked="state.form.deletable"
          enable-text="Enabled"
          disable-text="Disabled"
          @change="val=>state.form.deletable = val"
        />
      </div>


      <!--value type-->
      <div class="form-group col-12"
           v-if="!updating"
      >
        <label class="col-form-label">Select Value Type</label>
        <TreeSelect
          placeholder="Select Value Type"
          v-model="state.form.valueTypes.value"
          :options="state.form.valueTypes.options"
          :multiple="false"
          :searchable="false"
          :clearable="false"
        >
        </TreeSelect>
        <FormErrorMsg :error="state.form.valueTypes.error" />
      </div>

      <div
        v-if="state.form.valueTypes.value === 'option' && !updating"
      >
        <div class="col-12">
          <span class="col-form-label">Options:</span>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th class="">Option</th>
                <th class="text-center">Remove</th>
              </tr>
              </thead>
              <tbody>
                <tr
                  v-for="option in state.form.selectedOptions"
                  :key="option.id"
                >
                  <td class="item-name">{{ option.label }}</td>
                  <td class="text-center px-1">
                    <i
                      class="icon-circle-with-cross item-delete-icon p-1"
                      @click="handleOptionRemove(option.id)"
                    />
                  </td>
                </tr>
                <tr v-if="!state.form.selectedOptions" key="empty-type">
                  <td colspan="3" class="text-center p-3 fs15 text-muted">Nothing added, Please add option</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <input
            type="text"
            class="form-control mb-3"
            id="settingOption"
            placeholder="Option Name"
            v-model.trim="state.form.options.value"
            autocomplete="off"
            spellcheck="false"
          >
        </div>
        <div class="form-group col-12">
          <div class="d-grid gap-2">
            <button
              type="button"
              class="btn btn-primary"
              :disabled="!state.form.options.value.length"
              @click="handleOptionAdd"
            >
              <i class="icon-playlist_add fs20" /> Add to List
            </button>
          </div>
        </div>
      </div>

      <!--value-->
      <div class="form-group col-12" v-if="state.form.valueTypes.value">
        <label for="settingValue" class="col-form-label">
          Value: <span class="text-danger">*</span>
          <span class="text-info small" v-if="state.form.valueTypes.value === 'file'">Max file Size 1.5 MB</span>
        </label>

        <!--text input-->
        <div
          v-if="state.form.valueTypes.value === 'text'"
        >
          <div>
            <textarea
              class="form-control"
              id="settingValue"
              placeholder="Setting value"
              v-model="state.form.value.value"
              :maxlength="state.form.value.maxAllowed"
            />
          </div>
        </div>

        <!--option input-->

        <div
          v-if="state.form.valueTypes.value === 'option'"
        >
          <div>
            <TreeSelect
              placeholder="Select option"
              v-model="state.form.value.value"
              :options="state.form.selectedOptions"
              :multiple="false"
              :searchable="true"
              :clearable="false"
            >
            </TreeSelect>
          </div>
        </div>

        <!--bool input-->
        <div
          v-else-if="state.form.valueTypes.value === 'bool'"
        >
          <SwitchComp
            :checked="state.form.value.value"
            enable-text="Enabled"
            disable-text="Disabled"
            @change="val=>state.form.value.value = val"
          />
        </div>

        <!--file input-->
        <div
          class="form-group"
          v-if="state.form.valueTypes.value === 'file'"
        >
          <div class="custom-file">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="upload Image"
                readonly
                :value="fileName"
                @click="() => fileInputRef.click()"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-primary"
                  type="button"
                  id="button-addon2"
                  @click="() => fileInputRef.click()"
                >
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.mp4"
                    class="d-none"
                    ref="fileInputRef"
                    @change="handleFileUpload"
                  />
                  Choose File
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between">
          <FormErrorMsg :error="state.form.value.error" />
          <span />
          <BadgeComp
            v-if="state.form.valueTypes.value === 'text'"
            classes="mt-1"
            :styles="{height: 'fit-content'}"
            type="dar"
          >
            {{ state.form.value.value?.length || 0 }}/{{ state.form.value.maxAllowed }}
          </BadgeComp>
        </div>

        <!--file preview-->
        <div class="preview d-flex justify-content-center mt-2">
          <div
            v-if="state.form.value.value && !state.form.deleteValue"
            class="card"
          >
            <i
              v-if="updating && !state.form.value.isVideo && state.form.valueTypes.value === 'file'"
              class="icon-x delete-file-action"
              @click="handleFileDelete"
            />
            <div
              v-if="state.form.value.isVideo"
            >
              <video
                class="embed-responsive selected-file-preview"
                ref="videoPlayerRef"
                controls
                autoplay
              >
                <source
                  v-if="state.form.valueTypes.value === 'file'"
                  :src="state.form.value.filePreview || `${settingAssetUrl}${state.form.value.value}`" type="video/mp4"
                />
                Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
            <div
              v-else
              class="card-img-top user-uploaded-image"
            >
              <img
                class="img-fluid user-image selected-file-preview"
                v-if="state.form.valueTypes.value === 'file'"
                :src="state.form.value.filePreview || `${settingAssetUrl}${state.form.value.value}`"
                alt=""
              />
            </div>
          </div>
        </div>

      </div>

    <!--description-->
      <div class="form-group col-12"
      >
        <label class="col-form-label">
          Description:
          <span class="text-danger">*</span>
        </label>
        <div>
            <textarea
              class="form-control"
              id="settingDescription"
              placeholder="Setting Description"
              v-model="state.form.description.value"
            />
          <div class="d-flex justify-content-between">
            <FormErrorMsg :error="state.form.description.error" />
          </div>
        </div>
      </div>
    </form>

  </ModalComp>
</template>

<script>
import ModalComp from '@/components/Util/ModalComp';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import SwitchComp from '@/components/Util/SwitchComp';
import TreeSelect from '@tkmam1x/vue3-treeselect';
import { computed, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, required } from '@vuelidate/validators';
import ErrorHelper from '@/utils/ErrorHelper';
import Toaster from '@/utils/Toaster';
import BadgeComp from '@/components/Util/BadgeComp';
import { collect } from 'collect.js';
import { STORAGE_FULL_URL } from '@/utils/Urls';

export default {
  name: 'AppSettingAction',
  components: { BadgeComp, ModalComp, FormErrorMsg, SwitchComp, TreeSelect },
  emits: ['hide'],
  props: {
    setting: {
      type: [Object, Boolean],
      default: false
    },
    updating: Boolean,
    show: {
      type: Boolean,
      default: false
    },
  },

  setup (props, { emit }) {

    const store = useStore();

    const settingStore = store.state.setting;

    const fileInputRef = ref(null);
    const videoPlayerRef = ref(null);

    const state = reactive({
      modal: {
        show: false,
        title: 'New Setting Item',
        submitBtnText: 'Add Setting'
      },
      form: {
        name: {
          value: '',
          error: false,
          maxAllowed: 50
        },
        value: {
          value: null,
          error: false,
          maxAllowed: 300,
          isVideo: false,
          filePreview: ''
        },
        description: {
          value: '',
          error: false
        },
        options: {
          value: '',
          error: false
        },
        deleteValue: false,
        group: {
          value: 'app',
          options: [],
          error: false,
        },
        adminOnly: false,
        deletable: false,
        valueTypes: {
          value: 'text',
          options: [
            {
              label: 'Text',
              id: 'text'
            },
            {
              label: 'Boolean',
              id: 'bool'
            },
            {
              label: 'File',
              id: 'file'
            },
            {
              label: 'Option',
              id: 'option'
            }
          ],
          error: false
        },
        addNewGroup: false,
        submitting: false,
        selectedOptions: []
      },
    });

    // file input display file name
    const fileName = computed(() => {
      if (state.form.deleteValue) return '';

      return state.form.value.value?.name || state.form.value.value;
    });

    watch(() => props.show, (nv) => {
      state.modal.show = nv;

      // reset form on close
      if (!nv) resetForm();
    });

    // customize modal for update &
    // reset form when closed
    watch(() => props.updating, (nv) => {

      // reset the form if updating is false
      if (!nv) {
        // reset form
        resetForm();

        state.modal = {
          ...state.modal,
          title: 'New Setting Item',
          submitBtnText: 'Add Setting'
        };

        return;
      }

      // or customize modal
      state.modal = {
        ...state.modal,
        title: 'Update Setting Item',
        submitBtnText: 'Save Changes'
      };

      // populate values
      state.form.name.value = props.setting.key;
      state.form.description.value = props.setting.description;

      state.form.value.value = props.setting.value;
      state.form.value.isVideo = props.setting.value_type === 'file' && checkIfVideo(props.setting.value);

      state.form.group.value = props.setting.group;
      state.form.adminOnly = props.setting.admin_only;
      state.form.deletable = props.setting.is_deletable;
      state.form.valueTypes.value = props.setting.value_type;
    });

    state.form.group.options = computed(() => {
      const list = settingStore.data.settings;

      if (!list) return [];

      return Object.keys(list).map(groupName => ({
        id: groupName,
        label: groupName
      }));
    });

    watch(() => props.setting.options, (nv) => {
      const optionList = props.setting.options;

      if (!optionList) return [];

      state.form.selectedOptions = JSON.parse(props.setting.options).map(optionName => ({
        id: optionName,
        label: optionName
      }));
    });

    // reset value when value type changes
    watch(() => state.form.valueTypes.value, (nv) => {

      // don't reset when updating
      if (props.updating) return;

      const newState = {
        value: '',
        error: false,
        maxAllowed: 300,
        isVideo: false,
        filePreview: ''
      };

      if (nv === 'bool') {
        newState.value = true;
      }

      state.form.selectedOptions = [];

      state.form.value = newState;
    });

    // reset group value when creating new
    watch(() => state.form.addNewGroup, (nv) => {
      // only reset group name when not
      if (!nv) {
        state.form.group.value = null;
      }
    });

    const handleOptionAdd = () => {

      const option = state.form.options.value;

        state.form.selectedOptions.push({
          id: option,
          label: option
        });

      // clear selected
      state.form.options.value = '';
    };

    const handleOptionRemove = (optionId) => {
      state.form.selectedOptions = state.form.selectedOptions.filter(itm => itm.id !== optionId);
    };

    // validation start
    const validationRules = {
      form: {
        name: {
          value: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(50),
          }
        },
        group: {
          value: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(20)
          }
        },
        valueTypes: {
          value: {
            required
          }
        },
        value: {
          value: {
            required
          }
        },
        description: {
          value: {
            required
          }
        }
      }
    };

    const validator = useVuelidate(validationRules, state, { $autoDirty: true });

    watch(validator, () => {
      ErrorHelper.getValidationErrors(validator, (errors) => {
          state.form.name.error = errors['form.name.value'] || false;
          state.form.group.error = errors['form.group.value'] || false;
          state.form.valueTypes.error = errors['form.valueTypes.value'] || false;
          state.form.value.error = errors['form.value.value'] || false;
          state.form.description.error = errors['form.description.value'] || false;
        },
        {
          'form.name.value': 'Setting Name',
          'form.group.value': 'Group',
          'form.valueTypes.value': 'Type',
          'form.value.value': 'Value',
          'form.description.value': 'Description',
        }
      );
    });
    // validation end

    const resetForm = () => {
      state.form.name.value = '';
      state.form.description.value = '';
      state.form.value.value = '';
      state.form.value.filePreview = '';
      state.form.group.value = 'app';
      state.form.adminOnly = false;
      state.form.deletable = false;
      state.form.valueTypes.value = 'text';
      state.form.addNewGroup = false;
      state.form.deleteValue = false;
    };

    function handleClose (clearForm = false) {
      emit('hide', false);

      if (clearForm) {
        resetForm();
      }
    }

    function getFormData () {
      const data = {
        name: state.form.name.value,
        group: state.form.group.value,
        value: state.form.value.value,
        description: state.form.description.value,
        value_type: state.form.valueTypes.value,
        admin_only: state.form.adminOnly ? 1 : 0,
        is_deletable: state.form.deletable ? 1 : 0,
        delete_value: (props.updating && state.form.deleteValue) ? 1 : 0,
      };

      if (state.form.valueTypes.value === 'option') {
        data.options = state.form.selectedOptions.map(({ label }) => (label));
      }

      if (state.form.valueTypes.value === 'bool') data.value = state.form.value.value ? 1 : 0;

      // create form data
      const formData = new FormData();
      collect({ ...data }).map((value, key) => formData.append(key, value));

      return formData;
    }

    async function handleSubmit () {

      if (!ErrorHelper.checkValidity(validator)) return;

      state.form.submitting = true;

      try {

        const data = getFormData();

        if (props.updating) {
          await store.dispatch('setting/updateSetting', { id: props.setting.id, data });
        } else {
          await store.dispatch('setting/createSetting', data);
        }

        Toaster.successAlt({
          title: `Setting ${props.updating ? 'Updated' : 'Created'}`,
          message: `'${data.get('name')}' has been ${props.updating ? 'updated' : 'created'}`
        });

        // close the modal
        handleClose(true);

      } catch (e) {

        console.log(e);

        Toaster.error({
          title: 'Failed',
          message: e.message
        });

        ErrorHelper.mapServerError(e, (err, ex) => {
          state.form.name.error = ex(err.name);
          state.form.group.error = ex(err.group);
          state.form.valueTypes.error = ex(err.value_type);
          state.form.value.error = ex(err.value);
          state.form.description.error = ex(err.description);
        });

      }

      state.form.submitting = false;
    }

    const handleFileUpload = () => {
      const selectedFile = fileInputRef.value.files[0];

      state.form.value.value = selectedFile;

      const previewUrl = URL.createObjectURL(selectedFile);
      const isValidVideo = checkIfVideo(selectedFile);

      state.form.value.filePreview = previewUrl;

      // is it's video
      state.form.value.isVideo = isValidVideo;

      // update video player source so it can play updated file
      if (isValidVideo) videoPlayerRef.value.src = previewUrl;

      // reset delete file
      state.form.deleteValue = false;
    };

    const handleFileDelete = () => {
      state.form.deleteValue = true;
    };

    return {
      state,
      handleOptionAdd,
      handleOptionRemove,
      fileInputRef,
      fileName,
      videoPlayerRef,
      settingAssetUrl: `${STORAGE_FULL_URL}settings/`,
      handleClose,
      handleSubmit,
      handleFileUpload,
      handleFileDelete,
    };
  }
};

/**
 * Checks if the file is a valid video file
 * @param file : File|String
 */
function checkIfVideo (file) {
  if (file === null) return false;

  const allowedFormats = ['mp4'];

  const extension = (file instanceof File)
    ? file.name.split('.').slice(-1).pop()
    : file.split('.').slice(-1).pop()
  ;

  return allowedFormats.includes(extension);
}
</script>

<style lang="scss" scoped>
@import "src/assets/sass/_variables.scss";

.selected-file-preview {
  max-width: 100%;
  max-height: 350px;
}
.item-name {
  font-size: 15px;
}
.delete-file-action {
  position: absolute;
  right: 0;
  padding: 10px;
  border: 1px solid rgba($secondary2, 0.60);
  border-bottom-left-radius: 10px;
  font-size: 30px;
  background-color: rgba($secondary2, 0.50);
  color: $secondary2;
  cursor: pointer;
}
.table {
  border: 1px solid #e0e3ec;

  thead {
    background-color: #f3f3f3;

    th {
      padding: 0.4rem 1rem;
    }
  }

  td {
    padding: 3px;
  }
}
</style>
