<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title fs25">Application Setting</div>
          <div class="d-flex" v-if="isAuthorized(permissionsGroup.appSetting)">
            <!--Debug setting keys-->
            <button
              class="d-flex btn btn-sm btn-outline-light p-2 me-2 align-items-center btn-resize-standard"
              @click="getSettingDifference"
            >
              <Spinner
                v-if="state.settingDebugInProgress"
                class="me-1"
                type="li"
                size="20"
              />
              <i v-else class="icon-bug_report fs20" />
              <span class="hidden-sm-and-down">Debug Keys</span>
            </button>

            <!--Clear cache-->
            <button
              class="d-flex btn btn-sm btn-outline-light p-2 me-2 align-items-center btn-resize-standard"
              @click="sendClearCacheCommand"
            >
              <Spinner
                v-if="state.clearCacheInProgress"
                class="me-1"
                type="li"
                size="20"
              />
              <span v-else>
                <i class="icon-layers1 fs20"></i>
                <span class="hidden-sm-and-down">Clear Cache</span>
              </span>
            </button>

            <!--Add new-->
            <button
              v-if="isAuthorized(permissions.app_setting_create)"
              class="d-flex btn btn-sm btn-primary p-2 me-2 align-items-center btn-resize-standard"
              @click="handleActionToggle"
            >
              <i class="icon-add fs20"></i>Add New
            </button>
          </div>
        </div>

        <div class="card-body" v-loading="state.loading">

          <!--controls-->
          <div
            class="col-12"
            id="setting-search-group"
            :style="isMobile? state.style.searchBar.mobile : state.style.searchBar.desktop"
          >
            <div class="form-group">
              <div class="input-group">

                <TreeSelect
                  class="fs15"
                  id="settingKey"
                  placeholder="Select Setting"
                  v-model="state.selectedKeyId"
                  :options="state.settingOptions"
                  :multiple="false"
                  :searchable="true"
                  :clearable="false"
                  :disableBranchNodes="true"
                  :showCount="true"
                >
                  <template #option-label="{ node, shouldShowCount, count, labelClassName, countClassName }">
                    <label
                      class="fs15"
                      :class="`${labelClassName} ${node.isBranch ? 'fs16 text-capitalize':'fs15'}`"
                    >
                      {{ node.label }}
                      <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
                    </label>
                  </template>
                </TreeSelect>

                <div class="input-group-append text-nowrap mt-2 mt-sm-0">
                  <button
                    v-if="isAuthorized(permissions.app_setting_update)"
                    class="btn btn-dark h-100 me-2 text-white"
                    :disabled="!state.selectedSetting"
                    @click="handleAction(state.selectedSetting, constants.LIST_ITEM_ACTION_UPDATE)"
                  >
                    <i class="icon-edit"/> Edit Selected
                  </button>
                  <button
                    v-if="isAuthorized(permissions.app_setting_delete)"
                    class="btn btn-secondary h-100 text-white"
                    style="color: white !important;"
                    :disabled="!state.selectedSetting?.is_deletable"
                    @click="handleAction(state.selectedSetting, constants.LIST_ITEM_ACTION_DELETE)"
                  >
                    <Spinner
                      v-if="state.action.deleteLoading"
                      classes="me-1"
                      type="li"
                      size="20"
                    />
                    <i v-else class="icon-delete"/>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>


          <!--Value details-->
          <div class="col-12 p2">
            <div class="mt-5">
              <table v-if="state.selectedSetting" class="table setting-details-table">
                <tbody>
                <tr>
                  <td class="key"> Value
                    <i title="Inspect value"
                       :class="`inspect-value icon-arrow-with-circle-${ state.inspectValue ? 'left' : 'right'}`"
                       @click="state.inspectValue = !state.inspectValue"
                    />

                  </td>
                  <td v-if="!state.inspectValue" class="value main val">
                    <div v-if="state.selectedSetting.value_type === 'file' && state.selectedSetting.value">
                      <img
                        v-if="!isVideo" class="img-fluid img-thumbnail img-90"
                        :src="`${settingAssetUrl}${encodeURI(state.selectedSetting.value)}`"
                        alt=""
                      />

                      <video v-else controls class="embed-responsive img-fluid img-thumbnail admin-video">
                        <source
                          :src="`${settingAssetUrl}${encodeURI(state.selectedSetting.value)}`"
                          type="video/mp4"
                        />
                        Sorry, your browser doesn't support embedded videos.
                      </video>
                    </div>

                    <p
                      v-else-if="state.selectedSetting.value_type === 'text' ||
                      state.selectedSetting.value_type === 'option'"
                    >
                      {{ state.selectedSetting.value }}
                    </p>

                    <div v-else-if="state.selectedSetting.value_type === 'bool'">
                      <SwitchComp
                        :checked="state.selectedSetting.value"
                        disabled
                      />
                    </div>
                  </td>
                  <td
                    v-else-if="state.selectedSetting.value === null"
                    class="value main val"
                  >
                    <span class="text-muted">Empty</span>
                  </td>
                  <td
                    v-else
                    class="value main val">
                    <textarea
                      class="form-control"
                      v-model="state.selectedSetting.value"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="key">Admin Only</td>
                  <td class="value val">
                    <SwitchComp
                      :checked="state.selectedSetting.admin_only"
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td class="key">Deletable</td>
                  <td class="value val">
                    <SwitchComp
                      :checked="state.selectedSetting.is_deletable"
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td class="key">Group</td>
                  <td class="value val">{{ state.selectedSetting.group }}</td>
                </tr>
                <tr v-if="state.selectedSetting.value_type === 'option'">
                  <td class="key">Options</td>
                  <td class="value val">
                    {{ state.selectedSetting.options ? optionListingHandler(state.selectedSetting.options) : 'No Option Defined' }}
                  </td>
                </tr>
                <tr>
                  <td class="key">Value Type</td>
                  <td class="value val">{{ state.selectedSetting.value_type }}</td>
                </tr>
                <tr>
                  <td class="key">Description</td>
                  <td class="value val">{{ state.selectedSetting.description }}</td>
                </tr>
                <tr>
                  <td class="key">Created</td>
                  <td class="value val">{{ formatDate(state.selectedSetting.created_at) }}</td>
                </tr>
                <tr>
                  <td class="key">Updated</td>
                  <td
                    class="value val"
                    v-if="state.selectedSetting.created_at === state.selectedSetting.updated_at"
                  >
                    Never updated
                  </td>
                  <td
                    class="value val"
                    v-else
                  >
                    {{ formatDate(state.selectedSetting.updated_at) }}
                  </td>
                </tr>
                </tbody>
              </table>
              <div
                v-else
                class="lead text-center"
              >
                Select a setting to check its value
              </div>
            </div>
          </div>


          <!--Debug-->
          <div class="col-12"
               v-if="state.debugData"
          >
            <div class="card mt-3">
              <div class="card-header card-header-with-btn bg-light">
                <div class="card-title">Setting Debugger</div>
              </div>
              <div class="card-body row">

                <!--Missing in DB-->
                <div class="col-sm-12 col-md-6 p2">
                  <div class="table-responsive mt-5">
                    <table
                      class="table"
                      v-if="state.debugData.definition.length"
                    >
                      <thead>
                      <tr>
                        <th>Missing in DB</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        v-for="settingKey in state.debugData.definition"
                        :key="settingKey"
                      >
                        <td>{{ settingKey }}</td>
                      </tr>
                      </tbody>
                    </table>
                    <div
                      v-else
                      class="lead text-center"
                    >
                      No Keys Missing in DB
                    </div>
                  </div>
                </div>

                <!--Missing in Definition-->
                <div class="col-sm-12 col-md-6 p2">
                  <div class="table-responsive mt-5">
                    <table
                      class="table"
                      v-if="state.debugData.db.length"
                    >
                      <thead>
                      <tr>
                        <th>Missing in Definition</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        v-for="settingKey in state.debugData.db"
                        :key="settingKey"
                      >
                        <td>{{ settingKey }}</td>
                      </tr>
                      </tbody>
                    </table>
                    <div
                      v-else
                      class="lead text-center"
                    >
                      No Keys Missing in Definition
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <!--update Modal-->
          <AppSettingAction
            :show="state.showAction"
            :setting="state.action.subject"
            :updating="state.action.types.edit"
            @hide="handleActionToggle"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, watch } from 'vue';
import ItemTable from '@/components/Util/ItemTable';
import ButtonComp from '@/components/Util/ButtonComp';
import { useStore } from 'vuex';
import { constants } from '@/utils';
import AppSettingAction from '@/components/Setting/AppSettingAction';
import Spinner from '@/components/Util/Spinner';
import Settings from '@/Services/Settings';
import Toaster from '@/utils/Toaster';
import SwitchComp from '@/components/Util/SwitchComp';
import { isMobileOnly } from 'mobile-device-detect';
import { formatDate, isAuthorized, isEmptyObject } from '@/utils/Helper';
import collect from 'collect.js';
import TreeSelect from '@tkmam1x/vue3-treeselect';
import { STORAGE_FULL_URL } from '@/utils/Urls';
import permissions, { permissionsGroup } from '@/utils/permissions';
import SweetAlert from '@/utils/SweetAlert';

export default {
  name: 'AppSetting',
  components: { AppSettingAction, ItemTable, ButtonComp, Spinner, SwitchComp, TreeSelect },
  setup () {

    const store = useStore();
    const settingStore = store.state.setting;

    const state = reactive({
      showAction: false,
      loading: false,

      settingDebugInProgress: false,
      debugData: null,

      clearCacheInProgress: false,
      selectedKeyId: null,
      settingOptions: [],
      style: {
        searchBar: {
          mobile: {
            padding: '18px 2px 4px 2px'
          },
          desktop: {
            padding: '20px 15px 8px 15px'
          }
        },
        valueKeyCellWidth: '250px'
      },

      selectedSetting: null,
      inspectValue: false,

      action: {
        types: {
          edit: false,
        },
        subject: false,
        deleteLoading: false
      },
    });

    // make setting options for dropdown
    state.settingOptions = computed(() => {
      const list = settingStore.data.settings;

      if (!list) return [];

      const options = collect(list)
        .map((settings, subject) => {
          const parentGroup = {
            id: subject,
            label: subject,
            children: []
          };

          // include child's in parent
          settings.map(setting => {
            parentGroup.children.push({
              id: setting.id,
              label: setting.key,
            });
          });

          return parentGroup;
        })
      ;

      return options.toArray();
    });

    state.style.valueKeyCellWidth = computed(() => {
      return isMobileOnly ? '150px' : '250px';
    });

    const getSettings = async () => {

      const currentSettingItem = store.getters['setting/allSettings'];

      if (!isEmptyObject(currentSettingItem)) return;

      state.loading = true;

      await store.dispatch('setting/fetchAppSettings');

      state.loading = false;
    };

    const optionListingHandler = (options = []) => {
        return JSON.parse(options).toString()
    };

    // set selected setting based on dropdown selection
    watch(() => state.selectedKeyId, (keyId) => {
      const settings = settingStore.data.settings;

      if (!settings) return;

      state.selectedSetting = collect(settings).flatten(1).first(setting => setting.id === keyId);
    });

    onMounted(async () => {
      await getSettings();
    });

    const isVideo = computed(() => {
      if (state.selectedSetting.value === null) return false;
      if (state.selectedSetting.value_type !== 'file') return false;

      const videoFormat = ['mp4'];
      const extension = state.selectedSetting.value.split('.').slice(-1).pop();
      return videoFormat.includes(extension);
    });

    const showDelete = computed(() => {

      if (!state.selectedSetting) return true;

      return !state.selectedSetting.is_deletable;
    });

    const getSettingDifference = async () => {
      state.settingDebugInProgress = true;

      try {

        const { data } = await Settings.debugSettings();

        state.debugData = data.data;

        Toaster.success({
          title: 'Debug Complete',
          message: 'Setting debug complete, Check following section for details'
        });

      } catch (e) {
        Toaster.error({
          title: 'Failed',
          message: e.message
        });
      }

      state.settingDebugInProgress = false;
    };

    const sendClearCacheCommand = async () => {
      state.clearCacheInProgress = true;

      try {

        await Settings.clearStoredCache();

        Toaster.success({
          title: 'Cache Cleared'
        });

      } catch (e) {
        Toaster.error({
          title: 'Failed',
          message: e.message
        });
      }

      state.clearCacheInProgress = false;

    };

    const handleActionToggle = (value) => {

      // set the desired action or just toggle
      const newValue = !!value || !state.showAction;

      state.showAction = newValue;

      // if closing the action then make edit false
      if (!newValue) {
        state.action.types.edit = false;
        state.action.subject = false;

        // update selected (after update)
        state.selectedSetting = collect(settingStore.data.settings)
          .flatten(1)
          .first(setting => setting.id === state.selectedSetting?.id)
        ;
      }

    };

    const handleDelete = (item) => {

      if (!isAuthorized(permissions.app_setting_delete)) return;

      SweetAlert.confirmError({
        text: `This will permanently Delete '${item.key}'?`,
        preConfirm: async (status) => {

          if (!status) return;

          state.action.deleteLoading = true;

          try {

            await store.dispatch('setting/deleteSetting', item);

            Toaster.success({
              title: 'Deleted',
              message: `'${item.key}' has been deleted`
            });

            // reset selection
            state.action.types.edit = false;
            state.action.subject = false;
            state.selectedSetting = false;

          } catch (e) {

            Toaster.error({
              title: 'Deletion Failed',
              message: e.message
            });
          }

          state.action.deleteLoading = false;

        }
      });

    };

    const handleAction = (item, actionType) => {

      state.action.subject = item;

      switch (actionType) {
        case constants.LIST_ITEM_ACTION_UPDATE:
          if (!isAuthorized(permissions.app_setting_update)) return;
          state.action.types.edit = true;
          handleActionToggle(item);
          break;

        case constants.LIST_ITEM_ACTION_DELETE:
          if (!isAuthorized(permissions.app_setting_delete)) return;
          state.action.types.edit = true;
          handleDelete(item);
          break;
      }

    };

    return {
      state,
      optionListingHandler,
      settingAssetUrl: `${STORAGE_FULL_URL}settings/`,
      constants,
      isVideo,
      isMobile: isMobileOnly,
      showDelete,
      formatDate,
      getSettingDifference,
      sendClearCacheCommand,
      handleAction,
      handleActionToggle,
      isAuthorized,
      permissions,
      permissionsGroup
    };
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/variables";
#setting-search-group {
  background-color: $card-bg;

  .input-group {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 600px) {
  #setting-search-group .input-group {
    flex-wrap: nowrap;
  }
}

.key, .value {
  font-size: 18px;
}

.key {
  background-color: #f3f3f3;
  width: v-bind('state.style.valueKeyCellWidth');
  min-width: 130px;
  max-width: 250px;
}

.value:not(.main) {
  color: #9e9e9e;
}

.inspect-value {
  color: #007ae1;
  cursor: pointer;
}

.admin-video {
  width: 300px;
  height: auto
}
.btn {
  i {
    margin: 0;
  }
}
.vue-treeselect--single .vue-treeselect__input {
  color: $title-color !important;
}
</style>
